<template>
  <div class="px-4 py-0client">
    <!-- <div class="py-0 font-bold text-black border-b border-gray-300 title">
      客户管理
    </div> -->

    <!-- <a-button type="primary" @click="showModal">
      Open Modal
    </a-button> -->
    <a-modal width='350px'  okText="审核" :footer="null" cancelText="拒绝" v-model="visiblem" title="审核信息" >
      <p>
        <span style="width:120px;">手机&nbsp;&nbsp;:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span>{{checkData.tel}}</span>
      </p>
      <p>
        <span  style="width:120px;">商户名称:&nbsp;&nbsp;&nbsp;&nbsp;</span><span>{{checkData.title}}</span>
      </p>
      <p>
        <span  style="width:120px;">商户编码:&nbsp;&nbsp;&nbsp;&nbsp;</span><span>{{checkData.unified_social_credit_code}}</span>
      </p>


      <p>
        <span  style="width:120px;">商户地址:&nbsp;&nbsp;&nbsp;&nbsp;</span><span>{{checkData.address_details}}</span>
      </p>
      <p>
        <span  style="width:120px;">营业执照:&nbsp;&nbsp;&nbsp;&nbsp;</span><span><img :src="checkData.url"></span>
      </p>
      <div style="width:70%;margin:0 auto;height:30px;">
      <a-button style="float:left;" @click="oncancel(checkData.id)" type="primary" danger ghost>拒绝</a-button>
      <a-button style="float:right;" @click="handleOk(checkData.id)" type="primary">通过</a-button>
      </div>
    </a-modal>


    <div v-if="refresh" class="py-6 content_item">
      <div style="justify-content: left" class="flex items-center justify-between">
        <div class="flex items-center item">
          <div>账号或手机号查询：</div>
          <div class="py-2 pr-4 input_box">
            <a-input size="small" placeholder="请输入" v-model="searchKey" />
          </div>
          <a-button size="small" type="primary" @click="search">
            查询
          </a-button>
        </div>
        <div
        class="flex flex-wrap items-center justify-between item"
        v-show="show"
      >
      <div style="margin-left:20px;" class="flex items-center">

        
        <span class="px-2"></span>
        <!-- <div @click="show = !show" class="cursor-pointer shouqi">
          <span class="pr-1 text-primary">收起</span>
          <span class="etcfont etc-jiantou-copy-copy" v-if="show"></span>
          <span class="etcfont etc-jiantou-copy" v-else></span>
        </div> -->
      </div>


        <!-- <div class="flex items-center item">
          <div>商户名称：</div>
          <div class="py-2 pr-4 client_input_box">
            <a-input size="small" placeholder="请输入" v-model="nickname" />
          </div>
        </div>
        <div class="flex items-center item">
          <div>商户编码：</div>
          <div class="py-2 pr-4 client_input_box">
            <a-input size="small" placeholder="请输入" v-model="number" />
          </div>
        </div> -->
        <div class="flex items-center item">
          <div>账号：</div>
          <div class="py-2 pr-4 client_input_box">
            <a-input size="small" placeholder="请输入" v-model="username" />
          </div>
        </div>
        <div class="flex items-center item">
          <div>密码：</div>
          <div class="py-2 pr-4 client_input_box">
            <a-input-password
              size="small"
              placeholder="请输入"
              v-model="password"
            />
          </div>
        </div>
        <a-button size="small" type="primary" icon="plus" @click="addMember"
        >添加</a-button>
      </div>
      </div>

      <div class="pt-4">
        <!-- <div class="list_title">客户信息列表</div> -->
        <div class="flex justify-between p-2 my-3 rounded ">
          <!-- <div class="flex items-center">
            <span
              class="pr-1 etcfont etc-gantanhao-copy-copy text-primary"
            ></span>
            <span
              >已选择 <i>{{ selectedRowKeys.length }}</i> 项</span
            >
          </div> -->
          <!-- <div class="cursor-pointer delete" @click="deleteAll">删除</div> -->
        </div>
        <a-table
          :data-source="dataSource"
          :columns="columns"
      
          :scroll="{ x: 1000 }"
          :pagination="pagination"
          @change="change"
        >
          <template
            v-for="col in ['nickname', 'number']"
            :slot="col"
            slot-scope="text, record"
          >
            <div :key="col">
            
              <a-input
                v-if="record.editable"
                style="margin: -5px 0"
                :value="text"
                @change="(e) => handleChange(e.target.value, record.key, col)"
              />
              <template v-else>
                
                {{ text }}
              </template>
            </div>
          </template>
          <template slot="tags" slot-scope="tags, record">
            <span class="cursor-pointer" @click="aquireBalance(record)">
              <a-tag v-for="tag in tags" :key="tag" color="blue">
                {{ tag }}
              </a-tag>
            </span>
          </template>
          <template slot="operation" slot-scope="text, record">
            <div class="flex">
              <div class="pr-2 editable-row-operations">
                <span v-if="record.editable">
                  <a-popconfirm
                    title="确定修改吗？"
                    cancelText="取消"
                    okText="确定"
                    @confirm="() => save(record.key)"
                  >
                    <a class="pr-2">保存</a>
                  </a-popconfirm>
                  <a @click="() => cancel(record.key)" class="pr-2">取消</a>
                </span>
                <span v-else>
                  <a
                    :disabled="editingKey !== ''"
                    @click="() => edit(record.key)"
                    >编辑</a
                  >
                </span>
              </div>
              <a-popconfirm
                v-if="dataSource.length"
                title="确定删除吗？"
                cancelText="取消"
                okText="确定"
                @confirm="() => onDelete(record)"
              >
                <a href="javascript:;">删除</a>
              </a-popconfirm>
              <a v-if="record.merchant_id!=0" @click="showModal(record.merchant_id,record.phone)">
               &nbsp; 审核
              </a>

            </div>
          </template>
        </a-table>
      </div>
     
    </div>
    <a-modal
      v-model="visible"
      title="消费记录"
      @ok="visible = false"
      :width="900"
    >
      <div class="p-2">
        <a-table
          :data-source="records"
          :columns="columns2"
          :pagination="pagination2"
        ></a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  MemberList,
  MemberInfo,
  MemberAdd,
  MemberEdit,
  MemberDelete,
  MemberBalance,
  getMerchantInfo,
  passMerchant,
  rejectMerchant
} from "@/api/client";

const rowSelection = {};
	import indexConfig from '@/config/index.config';
export default {
  data() {
    return {
      refresh:true,
      checkData:{},
      visiblem: false,
      show: true,
      value: null,
      value2: null,
      editingKey: "", // 表格修改的key
      searchKey: "", // 搜索编码
      page: 1,
      total: 0,
      username: "",
      nickname: "",
      number: "",
      password: "",
      dataSource: [], // 表格数据源
      count: 2,
      cacheData: null,
      pagination: {
        // 关于页码的配置属性
        total: 0,
        pageSize:7,
        pageNo: 1,
        showSizeChanger: false, // 显示可改变每页数量
        pageSizeOptions: ["7"], // 每页数量选项
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) =>
          this.onSizeChange(current, pageSize), // 改变每页数量时更新显示
        onChange: (page, pageSize) => this.onPageChange(page, pageSize), //点击页码事件
      },
      columns: [
        {
          title: "账号",
          dataIndex: "username",
          key: "username",
          scopedSlots: { customRender: "username" },
          customRender: (text,record)=>{
            return text
          },
          width: 200,
        },
        {
          title: "手机号",
          dataIndex: "phone",
          key: "phone",
          scopedSlots: { customRender: "phone" },
          customRender: (text,record)=>{
            return text
          },
          width: 140,
        },
        // {
        //   title: "商户编号",
        //   dataIndex: "number",
        //   key: "number",
        //   scopedSlots: { customRender: "number" },
        //   width: 140,
        // },
        {
          title: "注册时间",
          dataIndex: "create_time",
          key: "create_time",
          scopedSlots: { customRender: "create_time" },
          customRender: (text,record)=>{
            return text.substr(0,10)
          },
          width: 160,
        },
        {
          title: "消费金额",
          dataIndex: "record",
          key: "record",
          sorter: (a, b) => parseFloat(a.record) - parseFloat(b.record),
          width: 100,
        },
        {
          title: "余额",
          dataIndex: "credit",
          key: "credit",
          sorter: (a, b) => parseFloat(a.credit) - parseFloat(b.credit),
          width: 100,
        },
        {
          title: "消费记录",
          dataIndex: "tags",
          key: "tags",
          scopedSlots: { customRender: "tags" },
          fixed: "right",
          width: 100,
        },
        {
          title: "审核状态",
          dataIndex: "certification_status",
          key: "certification_status",
          scopedSlots: { customRender: "certification_status" },
           customRender: (text,record)=>{
            switch(text){
              case 0:
                return '审核中'
                break;
                case null:
                return '未提交'
                break;
                case 1:
                return '已认证'
                break;
                case -1:
                return '已拒绝'
                break;
                default:
                return ''
                break;
            }
             // return text
          },
          // sorter: (a) => a.merchant_id == 0 ? 'zaishenhe':'buzai',
          fixed: "right",
          width: 100,
        },
        {
          title: "操作",
          dataIndex: "operation",
          key: "operation",
          fixed: "right",
          width: 140,
          scopedSlots: { customRender: "operation" },
        },
      ],
      // rowSelection,
      selectedRowKeys: [],
      visible: false,
      records: [],
      columns2: [
        {
          title: "时间",
          dataIndex: "create_time",
          key: "create_time",
          width: 150,
        },
        {
          title: "类型",
          dataIndex: "remark",
          key: "remark",
          width: 100,
        },
        {
          title: "消费金额",
          dataIndex: "num",
          key: "num",
          width: 100,
        },
        {
          title: "余额",
          dataIndex: "balance",
          key: "balance",
          width: 120,
        },
      ],
      pagination2: {
        // 关于页码的配置属性
        total: 0,
        pageSize: 10,
        pageNo: 1,
        showSizeChanger: false, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) =>
          this.onSizeChange2(current, pageSize), // 改变每页数量时更新显示
        onChange: (page, pageSize) => this.onPageChange2(page, pageSize), //点击页码事件
      },
      member_id: "",
    };
  },
  created() {
    this.cacheData = this.dataSource.map((item) => ({ ...item }));
    this.auquireClients();
  },
  methods: {
    onshenhe(){
      //alert('sss')
    },
    oncancel(e){
     // alert(e)
    //this.refresh = false
      rejectMerchant({merchant_id:e}).then((r)=>{
        if(r.code == 200){
          //alert('修改成功')
          // this.$nextTick(() => {
          //   this.refresh = true
          // })
          this.$message.info('拒绝成功');
          setTimeout(()=>{
            window.location.reload()

          },700)
         // window.location.reload()
         }
      })
    },
    my_check(){
     // alert('aaa')
    },
    showModal(idd,tel) {
      
     
      this.$nextTick(() => {
        this.checkData.tel = tel
       // alert(this.checkData.tel)
						})
            this.checkData.id = idd
      getMerchantInfo({
        merchant_id: idd,
      })
        .then((r) => {
         // alert('12123')
          if(r.code ==200){
            this.$nextTick(() => {
              
              this.checkData.title = r.data.title
           this.checkData.unified_social_credit_code = r.data.unified_social_credit_code
           this.checkData.address_details = r.data.address_details
           this.checkData.url = indexConfig.imgUrl+'/'+r.data.license_pic
           this.visiblem = true;
						})
           // this.checkData= {...r.data}
            console.log( this.checkData)
          }      
        })
       // console.log( this.checkData)
       
    },
    handleOk(e) {
     // console.log(e);
     // alert(e)
      this.visiblem = false;
     // this.refresh = false
      passMerchant({merchant_id:e}).then((r)=>{
        //alert(r)
         if(r.code == 200){
        
          this.$message.info('通过成功');
          setTimeout(()=>{
            window.location.reload()

          },700)
          // this.$nextTick(() => {
          //   this.refresh = true
          // })
         }
      })
    },
    details(e) {
      console.log(e);
    },
    // onChange(selectedRowKeys, selectedRows){
    //   console.log(
    //     `selectedRowKeys: ${selectedRowKeys}`,
    //     "selectedRows: ",
    //     selectedRows
    //   );
    // },
    // onSelect(record, selected, selectedRows){
    //   console.log(record, selected, selectedRows);
    //   this.selectedRowsLength = selectedRows.length;
    // },
    change(e) {
      // console.log(e)
      this.page = e.current;
      this.auquireClients();
    },
    onSelectChange(selectedRowKeys) {
      console.log("selectedRowKeys changed: ", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    onCellChange(key, dataIndex, value) {
      const dataSource = [...this.dataSource];
      const target = dataSource.find((item) => item.key === key);
      if (target) {
        target[dataIndex] = value;
        this.dataSource = dataSource;
      }
    },
    // 删除商户(单个)
    onDelete(record) {
      MemberDelete({
        member_ids: [record.member_id],
      })
        .then((res) => {
          console.log("删除商户", res);
          if (res.msg == "操作成功") {
            const dataSource = [...this.dataSource];
            this.dataSource = dataSource.filter(
              (item) => item.key !== record.key
            );
            this.$message.success("商户删除成功");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 删除用户 多个
    deleteAll() {
      if (this.selectedRowKeys.length == 0) {
        this.$message.error("您没有选择要删除的用户");
        return;
      }
      this.$confirm({
        title: "确定删除选择的商户吗？",
        content: "",
        onOk: () => {
          let list = [];
          this.selectedRowKeys.map((item) => {
            list.push(this.dataSource[item].member_id);
          });
          MemberDelete({
            member_ids: list,
          })
            .then((res) => {
              console.log("删除商户", res);
              if (res.msg == "操作成功") {
                let dataSource = [...this.dataSource];
                this.selectedRowKeys.map((list_item) => {
                  console.log(list_item);
                  this.dataSource = dataSource.filter(
                    (item) => item.key !== list_item
                  );
                  dataSource = [...this.dataSource];
                });
                this.$message.success("商户删除成功");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        },
        onCancel: () => {
          console.log("Cancel");
        },
        class: "test",
      });
    },
    handleAdd() {
      const { count, dataSource } = this;
      const newData = {
        key: count,
        name: `Edward King ${count}`,
        age: 32,
        address: `London, Park Lane no. ${count}`,
      };
      this.dataSource = [...dataSource, newData];
      this.count = count + 1;
    },
    handleChange(value, key, column) {
      const newData = [...this.dataSource];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    // 修改
    edit(key) {
      const newData = [...this.dataSource];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.dataSource = newData;
      }
    },
    // 保存
    save(key) {
      const newData = [...this.dataSource];
      const target = newData.filter((item) => key === item.key)[0];

      this.editingKey = "";
      if (target) {
        if (target.nickname == "" || this.isNull(target.nickname)) {
          this.$message.error("商户名称不能为空");
          return;
        }
        if (target.nickname.length < 2) {
          this.$message.error("商户名称不能低于2个字符");
          return;
        }
        if (target.number == "" || this.isNull(target.number)) {
          this.$message.error("商户编码不能为空");
          return;
        }
        if (target.number.length < 6) {
          this.$message.error("商户编码不能低于6个字符");
          return;
        }
        MemberEdit({
          member_id: target.member_id,
          username: target.username,
          nickname: target.nickname,
          number: target.number,
        })
          .then((res) => {
            if ((res.msg = "操作成功")) {
              this.$message.success("商户信息修改成功");
              Object.assign(
                target,
                this.cacheData.filter((item) => key === item.key)[0]
              );
            } else {
              this.$message.error(res.msg);
            }
            delete target.editable;
            this.dataSource = newData;
          })
          .catch((error) => {});
      }
    },
    cancel(key) {
      const newData = [...this.dataSource];
      const target = newData.filter((item) => key === item.key)[0];
      this.editingKey = "";
      if (target) {
        // Object.assign(
        //   target,
        //   this.cacheData.filter((item) => key === item.key)[0]
        // );
        delete target.editable;
        this.dataSource = newData;
      }
    },
    onChange(pagination, sorter) {
      console.log("params", pagination, sorter);
    },
    // 请求客户列表
    auquireClients() {
      MemberList({
        page: this.pagination.pageNo,
        limit: this.pagination.pageSize,
      })
        .then((r) => {
          console.log(r);
          let list = r.data.list;
          list.map((item, index) => {
            item.key = index;
            item.tags = ["查看详情"];
          });
          this.dataSource = [];
          this.dataSource.push(...list);
          this.pagination.total = r.data.count;
          console.log(this.pagination.total);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 分页里size改变触发的函数
    onSizeChange(_, pageSize) {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = pageSize;
      this.auquireClients(1);
    },
    // 页面改变时
    onPageChange(page, pageSize) {
      this.pagination.pageNo = page;
      this.auquireClients(page);
    },
    // 分页里size改变触发的函数
    onSizeChange2(_, pageSize) {
      this.pagination2.pageNo = 1;
      this.pagination2.pageSize = pageSize;
      this.aquireBalance();
    },
    // 页面改变时
    onPageChange2(page, pageSize) {
      this.pagination2.pageNo = page;
      this.aquireBalance();
    },
    // 客户详情
    aquireInfo(item) {
      MemberInfo({
        member_id: item.member_id,
      })
        .then((r) => {
          console.log("查看详情", r);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 客户消费记录
    aquireBalance(item) {
      if (item) {
        this.member_id = item.member_id;
      }
      MemberBalance({
        member_id: this.member_id,
        page: this.pagination2.pageNo,
        limit: this.pagination2.pageSize,
      })
        .then((r) => {
          if (r.code == 200) {
            if (r.data.count == 0) {
              this.$message.warning("暂无消费记录");
            } else {
              this.pagination2.total = r.data.count;
              this.visible = true;
              this.records = [];
              r.data.list.forEach((item) => {
                item.key = item.id;
              });
              this.records.push(...r.data.list);
            }
          } else {
            this.$message.error(r.msg);
          }
          console.log("查看详情", r);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 添加客户
    addMember() {
      if (this.nickname == "" || this.isNull(this.nickname)) {
        this.$message.error("商户名称不能为空");
        return;
      }
      if (this.nickname.length < 2) {
        this.$message.error("商户名称不能低于2个字符");
        return;
      }
      if (this.number == "" || this.isNull(this.number)) {
        this.$message.error("商户编码不能为空");
        return;
      }
      if (this.number.length < 6) {
        this.$message.error("商户编码不能低于6个字符");
        return;
      }
      if (this.username == "" || this.isNull(this.username)) {
        this.$message.error("账号不能为空");
        return;
      }
      if (this.username.length < 6) {
        this.$message.error("账号不能低于6个字符");
        return;
      }

      if (this.password == "" || this.isNull(this.password)) {
        this.$message.error("密码不能为空");
        return;
      }
      if (this.password.length < 6) {
        this.$message.error("密码不能低于6个字符");
        return;
      }
      // 添加商户
      MemberAdd({
        username: this.username,
        nickname: this.nickname,
        number: this.number,
        password: this.password,
      })
        .then((res) => {
          if (res.msg == "操作成功") {
            this.$message.success("添加商户成功");
            this.page = 1;
            this.auquireClients();
            console.log("添加商户", res);
            this.number = "";
            this.password = "";
            this.nickname = "";
            this.username = "";
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 判断是否为空
    isNull(str) {
      if (str == "") return true;
      var regu = "^[ ]+$";
      var re = new RegExp(regu);
      return re.test(str);
    },
    // 搜索商户
    search() {
      MemberList({
        number: this.searchKey,
        page: this.page,
        limit: this.pagination.defaultPageSize,
      })
        .then((r) => {
          console.log(r);
          let list = r.data.list;
          list.map((item, index) => {
            item.key = index;
            item.tags = ["查看详情"];
          });
          this.dataSource = [];
          this.dataSource.push(...list);
          this.pagination.total = r.data.count;
          console.log(this.pagination.total);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
}
.input_box {
  width: 250px;
}
.client_input_box {
  width: 180px;
}
.shouqi {
  user-select: none;
}
.list_title {
  font-size: 16px;
}
.select_info {
  background: #afe8bc;
  .etc-gantanhao-copy-copy {
    font-size: 18px;
  }
  span {
    font-size: 14px;
  }
  i {
    font-style: normal;
    padding: 0px 2px;
    color: @primary-color;
  }
  .delete {
    color: red;
    user-select: none;
  }
}
.content_item {
  .item {
    .item_title {
      font-size: 16px;
    }
    span {
      font-size: 14px;
    }
    .list_title {
      color: #000000;
    }
    .list_msg {
      color: #7c7c7c;
    }
    div {
      font-size: 14px;
      color: #000000;
    }
    .item_box {
      div {
        width: 25%;
      }
    }
    span {
      font-size: 14px;
    }
  }
}
</style>
<style lang="less">
.client {
  .editable-cell {
    position: relative;
  }

  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    line-height: 18px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell:hover .editable-cell-icon {
    display: inline-block;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
  tr td:last-child {
    display: flex;
    justify-content: flex-end;
    a:nth-child(1) {
      color: @primary-color;
    }
    a:nth-child(2) {
      color: red;
    }
  }
  th:last-child {
    text-align: right;
  }

  .ant-btn {
    display: flex;
    align-items: center;
  }
}
</style>
