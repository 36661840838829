const CONFIG = {
    // 开发环境配置
    development: {
        assetsPath: '/static', // 静态资源路径
				baseUrl: '/api', // 后台接口请求地址
             imgUrl:'https://photo-test.worldif.store',   //t图片请求地址
             // imgUrl:'/photo',   //t图片请求地址
				// baseUrl: '/api', // 后台接口请求地址
				// hostUrl: 'http://apirobot.worldif.local', // H5地址(前端运行地址)
        websocketUrl: '', // websocket服务端地址
        weixinAppId: '' // 微信公众号appid
    },
    // 生产环境配置
    production: {
        assetsPath: '/static', // 静态资源路径
        baseUrl: '/api', // 后台接口请求地址
        imgUrl:'https://photo.worldif.store',   //t图片请求地址
        hostUrl: 'https://robot.worldif.store', // H5地址(前端运行地址)
        websocketUrl: '', // websocket服务端地址
        weixinAppId: '' // 微信公众号appidapirobot.worldif.local
    }

};
let url = window.location.href

if(url.indexOf("admin-test") != -1 ){
    CONFIG.development.imgUrl = "https://photo-test.worldif.store"
}else{
    CONFIG.development.imgUrl = "https://photo.worldif.store"
}
export default CONFIG[process.env.NODE_ENV];